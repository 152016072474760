body {
    background-color: #000;
    display: contents;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
  }
  
  .bgdiv {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.5s ease-out;
    z-index: 8999;
  }
  
  .bgdiv.fade-out {
    opacity: 0;
  }
  
  .popup {
    font-family: monospace;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    padding: 20px;
    background-color: #8f8f8f;
    z-index: 8998;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
  
  .popup.fade-out {
    opacity: 0;
  }
  
  html, body {
    padding: 0;
    margin: 0;
  }
  
  .App {
    flex-grow: 1;
    font-family: 'Courier New', Courier, monospace;
  }

  .App-content {
  transition: opacity 0.5s ease-out;
  opacity: 1;
  padding-top: 30px;
}

.downloadpdf{
  color: #0a3fa8;
    text-decoration: none;
    font-size: large;
  }

  .downloadpdf:hover{
    color: #3e5e9e;
      text-decoration: none;
      font-size: large;
    }
    


.App-content.fade-out {
  opacity: 0;
}
  
  .navbar {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(20px);
    width: 50%;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-around;
    z-index: 9000;
    background-color: #000;
    transition: all 1s ease-in-out;
  }
  
  .navbar-move {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: none;
  }