
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
  min-height: 97.1vh;
 
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
  background-image: url('./bgimage.jpg');
  background-size: cover;    
  background-position: center;   
  background-repeat: no-repeat;  
  background-attachment: scroll; 
  background-position-y: 0px;
position: relative;
transition: background-image 0.2s ease-in-out;


}



.header {

  width: 100%;
  background-size:contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

 


}

.profilePhoto {
  width: 20%;
  
  object-fit: cover;


}

.author{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;

  flex:1;

}


.title {
  width: 80%;
  font-size: 1.5vw;
  padding: 20px;
  background-color: #174060;
  position: relative;
}

.navbar {
  position: absolute; /* Navbar will be positioned relative to the closest parent with a position other than 'static' */
  bottom: 0; /* Anchor Navbar to the bottom of the .title */
  width: 100%; /* Make Navbar span the full width of the .title */
  right: 0px;
  font-size: 1vw;

 
  width: 80%;



}

.navList {
  list-style-type: none;
  display: flex;
 
  justify-content: space-between;  /* Distribute space evenly among items */
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  align-items: baseline;

}

.navLink {
  color: white;
  text-decoration: none;
  text-overflow: ellipsis; 
  font-size: 1vw; 
  display: flex;
  align-items: center;
  align-self: stretch;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
       hyphens: auto;
       
}
.paragraphs{
  padding: 20px;
}
.navList li{
  text-align: center;

padding: 0.5vw 0.9vw;
}

.liLinkActive{
  background-color: black;
  

}

.contentWrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.25), 0px 13px 11px 6px rgba(0, 0, 0, 0.42);
  background: transparent;
  margin-bottom: 40px;


}

.content{
  display: flex;

  max-height: 64.4vh;
 min-height: 64.4vh;
}

.sidebar {
  font-size: 1vw;
  width: 20%;
  object-fit: cover;
  color: #fff;
 background-color: #103450;
 max-height: 64.4vh;
 min-height: 64.4vh;
 overflow: hidden;
  
}
.sidebartext{
  padding:20px;

}


h1{
  text-align: center;
  font-size: xx-large;
}
.sectionholder{
  width: 80%;
  background-color:#d9d9d9 ;
  overflow: scroll;
  overflow-x: hidden;
  }

  /* Styling the scrollbar for Webkit browsers (Chrome, Edge, Safari) */
.sectionholder::-webkit-scrollbar {
  width: 10px;  /* Width of the vertical scrollbar */
}

.sectionholder::-webkit-scrollbar-thumb {
  background-color: #617a8d;  /* Color of the draggable scrollbar */


}

.sectionholder::-webkit-scrollbar-track {
  background-color: #d9d9d9;  /* Background color of the scrollbar container */
  
}

/* Styling the scrollbar for Firefox */
.sectionholder {
  scrollbar-width: thin;
  scrollbar-color: #617a8d #d9d9d9;  /* First color is the draggable scrollbar, second color is the background of the scrollbar container */
}

.section {
  padding:20px;


 
}

.sectionTitle {
  font-size: 1.5vw;
  margin-bottom: 10px;
}

.paragraph {
  margin: 0 0 10px 0;
}




.skillsgrid{
 
    display: grid;
    
   
    text-align: center;
   
    width: 100%;
  
}

.sidebarLink {
  display: block;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
}

.footer {
  background-color: #333;
  color: white;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footerText {
  margin: 0;
}


@media (max-width: 768px) {

  .app{
  background-image: url('./bgimagesmol.jpg');
}

}