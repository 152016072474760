.app {
  font-family: 'Press Start 2P', cursive;
  color: #333;
  background-color: #4a6e3b;
  padding: 20px;
  line-height: 140%;
  min-height: 96vh;
  display: flex;
  flex-direction: column;
}

.app a{
  text-decoration: none;
}

.app table {
  border: 1px solid #000000;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}


.app th, .app td {
  padding: 15px;
  text-align: left;
  background-color: #ffff00;
  border: 1px solid #000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: auto;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color:#fff;
 
  text-shadow: 1px 1px #000;
  font-family: 'Press Start 2P', cursive;
  border: 2px solid #000;
 
 
  box-shadow: 0 0 2px #000;
  background: linear-gradient(to bottom right, #008080, #004d4d);
  position: relative;
}
.header img {
  height: 150px;
  border: 3px solid #000;
  padding: 3px;
  box-shadow: inset 0px 0px 0px 2px #fff, /* Simulating the window edge */
              0px 0px 10px rgba(0, 0, 0, 0.5);
  background: #fff;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}


.name {
  font-size: 2em;
  margin-top: 25px;
  text-shadow: 2px 2px 2px #000;
  color: #FFD700;
  background: linear-gradient(to bottom right, #012e2e, #004d4d);
  box-shadow: inset 1px 1px 2px rgb(139, 139, 139),
  inset -1px -1px 2px rgb(0, 0, 0);
  padding: 30px;
  border-radius: 5px;

  position: relative;
  z-index: 1;
}

.name:hover{

  background: linear-gradient(to bottom right, #014e4e, #022424);
}

.role {
  font-size: 1em;
  color: #37c4c4;
  text-shadow: 2px 2px 2px #000;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  

}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.skillsGrid {
  width: 100%; /* or any desired width */
  position: relative;
  z-index: 1;
}

.header img, .name {

  z-index: 2;
}

.titleholder{
  
    /* transform: translate(-50%, 50%); */
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;



}

.skillsGrid {
  display: grid;
  grid-template-columns: repeat(8, minmax(140px, 2fr));
  grid-gap: 0px;
  text-align: center;
  position: relative;
  width:100%;
  height: 100%;

  z-index: 1;

}

.header img, .name{


  z-index: 2;
}

.skill {
  
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: transparent;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2),
    inset -1px -1px 2px rgba(0, 0, 0, 0.5);
    background: linear-gradient(to bottom right, #014e4e, #022424);
  color: #fff;
  border: none;
  font-size: 0.8em;
  opacity: 0.15;
  padding: 20px;

}

.skill:hover{
position: relative;
  z-index: 40;
  opacity: 1;
}

.app img {
  max-width: 100%;
  height: auto;
}

.section{
  display: flex;
  flex:1;
}


.sectiontitle, .sectioncontent {
  margin-bottom: 30px;  
}

.navbar{
  position: relative;
}

.navlist{
  font-size: 1vw;
  margin-bottom: 0;  
  list-style-type: none; 
  display: flex;
  justify-Content: space-evenly;
  padding-inline-start: 0px;
  margin-right: 20px;
  margin-left:20px

}

.menuitem {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 10px 10px 0 0;
  padding: 20px;
  padding-top: 15px;
  padding-bottom: 7px;
  color: #333;
  background-color: #00808080;
  top: 20px;
  border: 2px solid #000;
  background: linear-gradient(to bottom, #015050, #008080); 
  box-shadow: inset 1px 1px 2px rgba(255,255,255,0.2),
              inset -1px -1px 2px rgba(0,0,0,0.5); 
  cursor:pointer;
  user-select: none;
  text-align: center;

 
  font-size: 1em;
}

.menuitem a {
  color: #000;
  text-decoration: none;
  text-shadow: 2px 2px 2px #969696;
  
}

.menuitem:hover { 
  background: linear-gradient(to top, #008080, #008080);
  box-shadow: inset 1px 1px 2px rgb(255, 255, 255),
  inset -1px -1px 2px rgba(0,0,0,0.5); 
}


.menuitem.active {
  min-height: 10vh;
  display: flex;
  align-items: center;
  background-color: #008080;
  background: linear-gradient(to bottom, #015050, #3bcccc); 
  font-size: calc(140%);
 line-height: 1.2;
  top: 0.29vh;
  border-bottom: none;
  position: relative;
  z-index: 4;
  box-shadow:none;
  box-shadow: inset 1px 1px 2px rgb(255, 255, 255);
}

.menuitem.active a{
  position: relative;

}

.sectiontitle {
  margin-left: 30px;
  display: inline-block;
  padding: 20px;
  font-size: 1.4em;
  font-weight: bold;
  color: #000;
  background-color: #004d4d80;
  border: 2px solid #000;
  background: linear-gradient(to bottom, #008080, #008080);
  box-shadow: inset 1px 1px 2px rgba(255,255,255,0.2), 
              inset -1px -1px 2px rgba(0,0,0,0.5);
  border-radius: 10px 10px 0 0;

  
}

.sectioncontent {
  word-wrap: break-word;
  position: relative;
  z-index: 2;
  padding: 20px;
  color: #000;
  background-color: #004d4d80;
  border: 2px solid #000;
  background: linear-gradient(to bottom, #37c4c4, #008080);
  box-shadow: inset 1px 1px 2px rgba(255,255,255,0.2), 
              inset -1px -1px 2px rgba(0,0,0,0.5);
  border-radius: 10px 10px 0 0;

  font-size: calc(0.7rem + 0.1vw); 
  flex: 1;
}

@media (min-width: 100px) and (max-width: 459px) {

  .skillsGrid {
    grid-template-columns: repeat(4, minmax(50px, 1fr));
    grid-gap: 0px;
  }
  .name{
    font-size:1em;
    padding: 15px;
  }

  .skill{
  
    padding: 1px;
    font-size:0.2em;
  }

  .sectioncontent{
    border-radius: 7px 7px 0 0;
  }

  .menuitem {
    padding: 4px;
    
  }
  .menuitem.active{
    padding: 10px;
  }
  .navbar .navlist {
    font-size: 1vw;
    padding-left: 2px;
    padding-right: 2px;
    
  }

  .navbar .menuitem {
    font-size: 1vw;
    
  }
    
    
}

@media (min-width: 460px) and (max-width: 767px) {

  .sectioncontent{
    border-radius: 7px 7px 0 0;
  }

  .skillsGrid {
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    grid-gap: 0px;
  }
  .name{
    font-size:1em;
    padding: 15px;
  }

  .skill{
    padding: 5px;
    font-size:0.6em;
  }

 
  .menuitem {
    padding: 5px;
    
  }
  .menuitem.active{
    padding: 10px;
  }
  .navbar .navlist {
    font-size: 1.8vw;
    padding-left: 8px;
    padding-right: 8px;
    
  }

  .navbar .menuitem {
    font-size: 0.7em;
    
  }
    
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 991px) {

  .skillsGrid {
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    grid-gap: 0px;
  }

  .menuitem {
    padding: 10px;
  }
  .navlist{
    padding: 0px;
  }
  .navbar .navlist {
    font-size: 1.4vw;
    
  }

  .navbar .menuitem {
    font-size: 0.7em;
    
  }
    
}

@media (min-width: 992px) and (max-width: 1165px) {

  .skill {
    padding:10px;
  }
  .skillsGrid {
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    grid-gap: 0px;
  }


  .menuitem {
    padding: 15px;
  }
  .navbar .navlist {
    font-size: 1.2em;
    
  }

  .navbar .menuitem {
    font-size: 1vw;
    
  }
    
}



