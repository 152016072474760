
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
  min-height: 97.4vh;
  background-color: #000000;
  font-family: Arial, sans-serif;
  background-position-y: 0px;
  position: relative;
  justify-content: center;



}

.aichat {
 
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
  font-family: Arial, sans-serif;
}

.question, .answer{
  width: 80%;
  border: none;
  outline: none;
  padding: 1em;
  margin: 1em 0;
  border-radius: 8px;
  font-size: 1.2em;
  resize: none;
 

}

.answer{
  height: 15vh;
  text-align: center;
color: #35e14c;

box-shadow: 0 4px 15px rgba(162, 36, 255, 0.6);
background: linear-gradient(174deg, #000000, #320032);


}

.errorMessage {
  color: rgb(214, 93, 93);
  font-size: 1.2em;
  margin-top: 20px;
  text-align: center;
}


.answer::-webkit-scrollbar {
  width: 10px;
  cursor: pointer !important;
  z-index: 100;
}

.answer::-webkit-scrollbar:hover {
  width: 10px;
  cursor: pointer;
}

.answer::-webkit-scrollbar-track {
  background: linear-gradient(174deg, #000000, #320032);
  border-radius: 8px;
}

.answer::-webkit-scrollbar-thumb {
  background-color: #35e14c;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(162, 36, 255, 0.6);
}

/* Firefox */
.answer {
  scrollbar-width: thin;
  scrollbar-color: #35e14c linear-gradient(174deg, #000000, #320032);
}

.questiondiv{
width: 85%;
display: flex;
align-items: center;
}

.question{
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 10px rgba(124, 255, 36, 0.6);
  height: 5vh;
  text-align: center;
  background-color: #a1c4fd;
}

.submitbutton {
  background: linear-gradient(45deg, #FF00FF, #800080);
  color: #35e14c;
  border: 2px solid #FF00FF;
  box-shadow: 0px 0px 10px #FF00FF, 0px 0px 10px #FF00FF, 0px 0px 10px #FF00FF;
  transition: all 0.3s ease-in-out;
  text-shadow: 0px 0px 5px #35e14c, 0px 0px 10px #35e14c, 0px 0px 5px #FF00FF;
  border-radius: 8px;
  font-size: 1.2em;
  resize: none;
  width: 20%;
  text-align: center;
  cursor: pointer;
  outline: none;
  padding: 0.5em;
  margin: 0.5em 0;
  margin-left: 0.8em;
}

.submitbutton:hover {
  background: linear-gradient(45deg, #800080, #FF00FF);
  box-shadow: 0px 0px 15px #FF00FF, 0px 0px 25px #FF00FF, 0px 0px 20px #FF00FF;
  transform: translateY(-1px);
}

.submitbutton:active {
  transform: translateY(0);
}

.radiogroup {
  font-size: 0.7vw;
  display: grid;
  grid-template-rows: auto auto; /* Two rows: one for AI, one for others */
  grid-template-columns: repeat(5, 1fr); /* Four equal columns for the other options */
  gap: 10px; /* Spacing between grid items */
  align-items: center; /* Center items vertically */
  justify-items: center; /* Center items horizontally */
  color: #FF00FF;
}

.prequestions {
  font-size: 0.7vw;
  width: 85%;
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  align-items: start;
  justify-items: center;

}

.prequestions button {
  background: none;  /* Remove the button background */
  border: none;      /* Remove the button border */
  color: #a1a1a1;   /* Use a subtle color for the text */
  cursor: pointer;  /* Change the cursor to pointer to indicate it's clickable */
  font-size: inherit; /* Inherit the font-size from the parent */
  transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out; /* Smooth transition */
  outline: none;    /* Remove the browser default outline */
  padding: 0;       /* Remove padding */
}

.prequestions button:hover {
  color: #35e14c;   /* Change the color on hover */

}

.submitbutton:active {
  transform: translateY(0);
}

.contentWrapper {

  width: 50%;
  box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.25), 0px 13px 11px 6px rgba(0, 0, 0, 0.42);
  background: rgba(25, 25, 25, 0.85);
  margin-bottom: 40px;

  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 0px 10px #35e14c, 0px 0px 10px #35e14c, 0px 0px 10px #35e14c
}


.radiogroup input[type="radio"] {
  display: none;
}
.radiogroup label {
  position: relative;
  padding-left: 0;
  padding-top: 30px;
  cursor: pointer;
  font-size: 1.2em;
  display: inline-block;
  margin-right: 20px;
}

.radiogroup label span {
  color: #35e14c;
  position: relative;
  z-index: 3;
}

.radiogroup label::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #35e14c;
  border-radius: 50%;
  background-color: transparent;
  z-index: 1;
}

.radiogroup label span::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #800080;
  opacity: 0;
  z-index: 2;
}

/* When the radio is checked, show the filled circle */
.radiogroup input[type="radio"]:checked + span::after {
  opacity: 1;
  top:-24px;
}




body {
  background-color: black;
  font-family: 'Arial', sans-serif;
}

.sectionholder{
  display: flex;
  flex-direction: column;
}

.headerh1 {
  font-size: 3em;
  background: -webkit-linear-gradient(45deg, #FF00FF, #800080, #FF00FF);
  background: linear-gradient(45deg, #FF00FF, #800080, #FF00FF);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 0px 5px #FF00FF, 0px 0px 2px #FF00FF, 0px 0px 10px #FF00FF;
  border: 2px solid #FF00FF;
  padding: 20px;
  display: inline-block;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #FF00FF, 0px 0px 20px #FF00FF, 0px 0px 30px #FF00FF;
  letter-spacing: 2px;
  animation: glowing 1.5s infinite alternate;
}

@keyframes glowing {
  0% {
      box-shadow: 0px 0px 5px #FF00FF, 0px 0px 10px #FF00FF, 0px 0px 15px #FF00FF;
  }
  100% {
      box-shadow: 0px 0px 20px #FF00FF, 0px 0px 30px #FF00FF, 0px 0px 40px #FF00FF;
  }
}
