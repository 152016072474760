.app {
  font-family: 'Berlin Sans FB';
  color: #333;
  background-color: #fff;
  padding: 20px;
  line-height: 140%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
 

}

.app a{
  text-decoration: none;
}


.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  color:#fff;

  border: 2px solid #000;


  
}

.header img {
  position: relative;
  z-index: 1;
}


.name {
  font-size: 2em;
  margin-top: 25px;
  text-shadow: 2px 2px 2px #000;
  color: #FFD700;
  background: linear-gradient(to bottom right, #012e2e, #004d4d);
  box-shadow: inset 1px 1px 2px rgb(139, 139, 139),
  inset -1px -1px 2px rgb(0, 0, 0);
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}


.role {
  font-size: 1em;
  color: #37c4c4;
  text-shadow: 2px 2px 2px #000;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  

}




.app img {
  max-width: 100%;
  height: auto;
}

.section{
  display: flex;
  flex:1;
}


.sectiontitle, .sectioncontent {
  margin-bottom: 30px;  
}

.navbar{
  position: relative;
  width: 100%;

}

.navlist {
  position: relative;
  margin-bottom: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-flow: row dense;
  gap: 3px;

  width: 100%;
}

.activeOverlay{
  overflow: hidden;

}




.menuitem, .textholder {
  position: relative;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 5px;
  color: #fff;
  cursor:pointer;
  user-select: none;
  overflow: hidden;
  
  
}


.textholder::-webkit-scrollbar {
  width: 10px;
}

.textholder::-webkit-scrollbar-track {
  background: transparent; /* Make track transparent */
}

.textholder::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make thumb transparent */
  border-radius: 0px;
}

/* On hover, or when the content overflows and scrolling is needed */
.textholder:hover::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4);
}

/* For Firefox */
.textholder {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; /* Make both thumb and track transparent */
}

.textholder:hover {
  scrollbar-color: rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.2);
}

.fulltext{
  padding: 20px;
}


.fadeintext{



  opacity: 0; /* start from fully transparent */
  animation: fadeIn 0.5s forwards; /* play the fadeIn animation over 2 seconds, then hold the final state */
}

.fadeouttext{
  opacity: 1; /* start from fully opaque */
  animation: fadeOut 0.5s forwards; /* play the fadeOut animation over 2 seconds, then hold the final state */
}

/* Define the fadeIn animation */
@keyframes fadeIn {
  to {
    opacity: 1; /* end at fully opaque */
  }
}

/* Define the fadeOut animation */
@keyframes fadeOut {
  to {
    opacity: 0; /* end at fully transparent */
  }
}



.menuitem.small {
  
  grid-column-end: span 3; /* Small items take up 1 column */
  height: 25vh;
}

.menuitem.medium {
 
  grid-column-end: span 1.5; /* Medium items take up 2 columns */
  height: 25vh;
}

.menuitem.large {
 
  grid-column-end: span 6; /* Large items take up 3 columns */
  height: 25vh;
}




@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.sectiontitle {

font-size: calc(0.8rem + 1.5vh); 
line-height: 100%;


  
}

.sectioncontent {
  padding-right:40px;
  position: relative;
  z-index: 2;
  color: #fff;
  flex: 1;
}

