
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
}

.header {
  width: 100%;
 
  background-image: url('./bg.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.profilePhoto {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 20px;
}



.title {
  font-size: 2em;
  margin-bottom: 20px;
}

.navbar {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 0;
  width: 100%;
}

.navList {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}


.navLink {
  color: white;
  text-decoration: none;
  padding: 10px;
}

.navLinkactive{

  background-color: black;
  

}

.contentholder{
display: flex;
flex: 1;
width: 90%;
}

.section {
  background-color: #ddd;
  padding: 20px;
 
  width: 100%;
 
}

.sectionTitle {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.paragraph {
  margin: 0 0 10px 0;
}

.sidebar {
  background-color: #333;
  color: white;
  padding: 10px;
  width: 280px;
  text-align: center;
  min-height: 400px;
}

.skillsgrid{
 
    display: grid;
    
   
    text-align: center;
   
    width: 100%;
  
}

.sidebarLink {
  display: block;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
}

.footer {
  background-color: #333;
  color: white;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footerText {
  margin: 0;
}
