/* App.module.css */

.App {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
  background-color: #F5F5F5; /* Light background color */
  overflow-y: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

}


.section {
  background-color: white; /* Change background color to white */
  border: 1px solid #aaa; /* Add border */
  color: #000;
  border-radius: 5px;
  padding: 20px;
 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add shadow */
  -webkit-animation: puff-in-center 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
	        animation: puff-in-center 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}


.loadingpage {
  position: relative;

  height: 100px;
  color: #000;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex:1;
}

.animation {
 
  height: 50px;
  border-radius: 50%;
  position: relative;
  margin: 20px auto;
  background: linear-gradient(#8e9eab, #eef2f3);
  opacity: 0;
  border-radius: 50%;

}

.animationActive {
  transform: translate(0px, 10px);
  margin-left: 2px;
  position: relative;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  opacity: 1;
  animation: bounce 1s ease-in-out infinite;
  background: linear-gradient(#8e9eab, #eef2f3);
}

.animationActive:nth-child(2) {
  animation-delay: 0.2s;
}

.animationActive:nth-child(3) {
  animation-delay: 0.4s;
}


@keyframes bounce {
  0%, 100% {
    transform: translate(0px, 10px);
  }
  50% {
    transform: translate(0px, 0px);
  }
}


.animation:nth-child(1) {
  animation-delay: 0s;
}

.animation:nth-child(2) {
  animation-delay: 0.2s;
}

.animation:nth-child(3) {
  animation-delay: 0.4s;
}




.section h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add text shadow */
}

.section p {
  line-height: 1.5;
  margin-bottom: 10px;
}

.navbar {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  border-bottom: 1px solid #f5f5f5;
}

.navbar button {
  background: linear-gradient(#8e9eab, #eef2f3);
  border: 1px solid #aaa; /* Add border */
  border-radius: 5px;
  padding: 10px 20px;
  color: #333;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add shadow */
}

.navbar button:hover {
  background: linear-gradient(#8e9eab, #cce5ff);
}



.profilePic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin: 20px auto;
  border: 2px solid #aaa; /* Add border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add shadow */
}

.menu {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #f5f5f5;
  transition: transform 0.3s ease-in-out;
}

.menu button {
  background: linear-gradient(#8e9eab, #eef2f3);
  border: 1px solid #aaa; /* Add border */
  border-radius: 5px;
  padding: 10px 20px;
  color: #333;
  cursor: pointer;
  font-size: calc(1.2rem - 0.1vw); 
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.menu button:hover {
  background: linear-gradient(#8e9eab, #cce5ff);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
  transform: scale(1.05); /* Add a slight scale effect on hover */
}

@keyframes slideInFromLeft {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
}

.carousel {
  animation: slideInFromLeft 1s ease-out;
}


.socialMedia {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.socialMedia a {
  margin: 0 5px;
  font-size: 20px;
  color: #333;
  text-decoration: none;
  background-color: #8e9eab;
  padding: 5px;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.socialMedia a:hover {
  transform: scale(1.2);
}


.footer {
  position: relative;
  overflow: hidden;
  border: 1px solid #aaa;
  background: linear-gradient(#8e9eab, #eef2f3);
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}


.dogAnimation {
  position: absolute;
  bottom: -33px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  animation: walk 16s linear infinite;
}

@keyframes walk {
  0% {
    transform: translateX(-100px);
    background-image: url('dog-animation.gif');
  }
  50% {
    transform: translateX(calc(100% + 100px));
   background-image: url('dog-animation.gif');
  }
  51%{
 background-image: url('dog-animation-flipped.gif');
  }
  100% {
    transform: translateX(-150px);
    background-image: url('dog-animation-flipped.gif');
  }
}


/* App.module.css */

/* Existing styles *//* App.module.css */

/* Existing styles */

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #aaa;
  background: linear-gradient(#8e9eab, #eef2f3);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 0px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.profilePic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  border: 2px solid #aaa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Add subtle shadow */
  transition: transform 0.3s ease-in-out;
}

.profilePic:hover {
  transform: scale(1.1); /* Add a slight scale effect on hover */
}

.headerInfo {
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
}

.headerInfo h1 {
  font-size: 24px;
  margin-bottom: 5px; /* Reduce the margin-bottom */
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  transition: color 0.3s ease-in-out;
}

.headerInfo:hover h1 {
  color: #337ab7; /* Change the color to your preference */
}

.headerInfo h2 {
  font-size: 18px;
  transition: color 0.3s ease-in-out;
}

.headerInfo:hover h2 {
  color: #5cb85c; /* Change the color to your preference */
}
