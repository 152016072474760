
.app {
  background-color: #d6d87d;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Helvetica', 'Arial', sans-serif;
  color: #000000;

}
  
 .app a {
    color: #0000ff;
    text-decoration: underline;
  }
  
  .app table {
    border: 1px solid #000000;
    table-layout: fixed;
    width: 100%;
  }
  
  .app th, .app td {
    padding: 15px;
    text-align: left;
    
  }

  .paragraphs{
    padding: 20px;
  }
  
  .app img {
    max-width: 100%;
    height: auto;
  }


  