

@font-face {
  font-family: 'CustomFont';
  src: url('./Terminal.ttf');
  /* You can include different font file formats for better cross-browser support */
}

.container{
  color: #20c20e;
  background-color: #000;
  width: 80%;
  margin: 0 auto;
  font-family: 'CustomFont';

  border: 2px solid #20c20e;
  border-radius: 5px;
  padding: 20px;
  line-height: 130%;
  letter-spacing: 2px;


}

.app {
  padding: 20px;
  background-color: #000;
 min-height: 100vh;

}


.app h1, .app h2, .app p {
  color: #20c20e;
 
}

.app h2{
  letter-spacing: 6px;
}

.app button {
  background-color: #000;
  color: #20c20e;
  border: 1px solid #20c20e;
}

.app button.selected {
  background-color: #20c20e;
  color: #000;
}

.app table {
  border: none;
  width: 100%;
}

.app td {
  border: none;
}
.typewriter-text {
  overflow: hidden;
  white-space: pre;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 1ms steps(40, end);
  border-right: 0.15em solid orange;
  padding-right: 0.5em;
  animation-fill-mode: forwards;
  animation-duration: 3.5s;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
