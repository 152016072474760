.app {
  font-Family: 'Comic Sans MS';
  color: #333;
  background-color: #f5f5f5;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app h1 {
  color: #0099cc;
  border-bottom: 1px solid #f5f5f5;
}

.app h2 {
  color: #006699;
  margin-top: 20px;
}
.app p {
  line-height: 1.6;
}

.app a {
  color: #0099cc;
  text-decoration: none;
}

.app a:hover {
  text-decoration: underline;
}

.app .btn {
  display: inline-block;
  background-color: #0099cc;
  color: #fff;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  text-decoration: none;
}

.app a:hover {
  background-color: #0d2d3d;
}

.app nav {
  background-color: #0099cc;
}

.app nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.app nav li {
  float: left;
}

.app nav li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.app nav li a:hover {
  background-color: #04202e;
}

.app header h1{
  color: #fff;
}